import { Link } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import * as React from "react";

import { Layout } from "@/components/Layout";

const AboutPage: React.FC = () => {
  return (
    <Layout>
      <GatsbySeo title="Tag" />
      <div className="prose max-w-full prose-h2:border-l-4 prose-h2:border-yellow-400 prose-h2:pl-2 ">
        <h1 className="pt-12  pb-8 md:text-6xl">About</h1>
        <h2>About this site</h2>
        <p>
          Great description about this site. People reading this will be
          immediately interested in posts of this site.
        </p>

        <strong>Related links</strong>
        <ul>
          <li>
            <Link to="/rss.xml">Feed</Link>
          </li>
          <li>
            <Link to="/privacy">Privacy</Link>
          </li>
        </ul>
        <h2>About author ー Your Name</h2>
        <p>
          Great description about you. People reading this will be immediately
          interested in your post.
        </p>
      </div>
    </Layout>
  );
};

export default AboutPage;
